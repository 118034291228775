import React, { useEffect } from 'react'
import EmbroideryAllitems from '../EmbroideryDesign/EmbroideryAllitems'
import Footer from '../Footer/Footer'

const Embroidery = () => {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      },[])
    return (
        <>
            <EmbroideryAllitems/>
            <Footer/>
        </>
    )
}

export default Embroidery
