import { Button } from "@mui/material";
import { Input, message } from "antd";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../Config";

const ResetPassword = () => {
    const { id } = useParams();
    const [resetData, setResetData] = useState({
      password: "",
      conformpass: "",
    });
  
    const [messageApi, contextHolder] = message.useMessage();
  
    const successmessage = (content: any) => {
      messageApi.open({
        type: "success",
        content: content,
      });
    };
  
    const errormessage = (content: any) => {
      messageApi.open({
        type: "error",
        content: content,
      });
    };
    const navigate=useNavigate()
    const handleReset = async () => {
      if (resetData.password !== resetData.conformpass) {
        errormessage("Password and conform password must be same");
      }
      else if(resetData.password===""||resetData.conformpass===""){
        errormessage("Please fill both password and conform password");
      } else {
        try {
          const res = await axios.patch(
            `${API_URL}/api/user/auth/resetPassword/${id}`,
            { password: resetData.password }
          );
          successmessage("Password updated")
          navigate("/")
        } catch (error:any) {
          if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              if (error.response.data.message === "User not found") {
                  errormessage("User not found !");
              } else {
                  errormessage(error.response.data.message);
              }
              errormessage(error.response.data.message);
            } else {
              errormessage("An unexpected error occurred. Please try again.");
            }
        }
      }
    };

  return (
    <>
    {contextHolder}
      <section>
        <Container>
          <div className="row" style={{ height: "100vh" }}>
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div>
                <img
                  className="img-fluid"
                  width={"400px"}
                  src="/assets/reset.png"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div className="py-3 login-box">
                <h2 className="title text-center">Welcome</h2>
                <p className=" text-center">
                  Enter your new password
                </p>
               
                <label className="label py-2">Password</label>
                <br />
                <Input.Password
                  placeholder="Enter your password"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e) =>
                    setResetData({ ...resetData, password: e.target.value })
                  }
                />
                <br />
                <label className="label py-2">Conform Password</label>
                <br />
                <Input.Password
                  placeholder="Enter your conform password"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e) =>
                    setResetData({ ...resetData, conformpass: e.target.value })
                  }
                />
                <br />
                <div className="text-center log-btn">
                  <Button variant="contained" className="updatebtn" onClick={handleReset}>Update</Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ResetPassword;
