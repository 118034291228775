import React, { useEffect } from 'react'
import PrintDesign from '../PrintDesign/PrintDesign'
import Footer from '../Footer/Footer'

const Print = () => {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      },[])
    return (
        <>
           <PrintDesign/>
           <Footer/> 
        </>
    )
}

export default Print
