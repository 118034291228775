import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Footer from "../Footer/Footer";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { deleteitem, getMe } from "../Providers/Apicalls";
import { ApiEndPoints } from "../Providers/api_endpoints";

const Successmeg = () => {
  const getUser = async () => {
    try {
      const getMeUser = await getMe(ApiEndPoints("getMe"));
      for (const item of getMeUser.user.cart) {
        await handleDelete(item);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: any) => {
    try {
      await deleteitem(ApiEndPoints("removeFromCart"), { product: id });
      await getUser();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <section className="py-4" style={{ backgroundColor: "#fff" }}>
        <Container className="con">
          <div className="d-flex justify-content-center align-items-center">
            <div className="asd text-center">
              <img className="img-fluid success-gif" src="/assets/safety.gif" />
              <p className="mb-0">Payment Done Successfully.</p>
              <p>Thank you for purchasing our product.</p>
              <Link to="/my-orders">
                <Button>Continue</Button>
              </Link>
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Successmeg;
