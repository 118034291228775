import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Card from "../Card/Card";
import { Dropdown, Space } from "antd";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Pagination } from "@mui/material";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { allProducts } from "../Providers/Apicalls";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  tags?: string;
}

const items: MenuProps["items"] = [
  {
    key: "1",
    label: <span>Sort By Popularity</span>,
  },
  {
    key: "2",
    label: <span>Sort By Average rating</span>,
  },
  {
    key: "3",
    label: <span>Sort By Least rating</span>,
  },
  {
    key: "4",
    label: <span>Sort By Price: Low to High</span>,
  },
  {
    key: "5",
    label: <span>Sort By Price: High to Low</span>,
  },
];

const ITEMS_PER_PAGE = 8;

const TagProduct = () => {
  const [page, setPage] = useState(1);
  const [ogData, setOgData] = useState<Product[]>([]);
  const [sortedData, setSortedData] = useState<Product[]>([]);
  const { tag } = useParams<{ tag: string }>();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const getAllProduct = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("getAllProduct"));
      const allProduct = firstResponse.products.filter(
        (product: Product) =>
          product.isFreebie === false &&
          product.tags?.toLocaleLowerCase() === tag
      );
      setOgData(allProduct);
      setSortedData(allProduct);
    } catch (error) {
      console.error(error);
    }
  };

  const handleMenuClick: MenuProps["onClick"] = ({ key }) => {
    let sortedArray = [...ogData];
    switch (key) {
      case "1":
        sortedArray.sort((a, b) => (b.buyersCount || 0) - (a.buyersCount || 0));
        break;
      case "2":
        sortedArray.sort(
          (a, b) => (b.ratingsAverage || 0) - (a.ratingsAverage || 0)
        );
        break;
      case "3":
        sortedArray.sort(
          (a, b) => (a.ratingsAverage || 0) - (b.ratingsAverage || 0)
        );
        break;
      case "4":
        sortedArray.sort((a, b) => (a.price || 0) - (b.price || 0));
        break;
      case "5":
        sortedArray.sort((a, b) => (b.price || 0) - (a.price || 0));
        break;
    }
    setSortedData(sortedArray);
  };

  const paginatedData = sortedData.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );

  const startItem = (page - 1) * ITEMS_PER_PAGE + 1;
  const endItem = Math.min(page * ITEMS_PER_PAGE, sortedData.length);

  useEffect(() => {
    getAllProduct();
  }, [tag]);

  const metaFunc = (tag: string) => {
    switch (tag) {
      case "adventure":
        return (
          <Helmet>
            <title>
              Adventure Embroidery Designs | RG Embroidery Designs - Explore
              Unique Patterns
            </title>
            <meta
              name="description"
              content="Embark on a creative journey with our Adventure embroidery designs at RG Embroidery Designs. Explore unique and intricate patterns inspired by travel, nature, and adventure. Perfect for your next project."
            />
          </Helmet>
        );
      case "animals":
        return (
          <Helmet>
            <title>
              Animal Embroidery Designs | RG Embroidery Designs - Wild &
              Wonderful Patterns
            </title>
            <meta
              name="description"
              content="Discover a diverse range of Animal embroidery designs at RG Embroidery Designs. From wild to domestic, find detailed and captivating patterns to bring your projects to life. Shop now for high-quality animal designs."
            />
          </Helmet>
        );
      case "awareness":
        return (
          <Helmet>
            <title>
              Awareness Embroidery Designs | RG Embroidery Designs - Inspire &
              Support
            </title>
            <meta
              name="description"
              content="Support and inspire with our Awareness embroidery designs at RG Embroidery Designs. Find meaningful patterns that promote causes and spread awareness. Perfect for creating impactful and thoughtful projects."
            />
          </Helmet>
        );
      case "back-to-school":
        return (
          <Helmet>
            <title>
              Back To School Embroidery Designs | RG Embroidery Designs - Fun &
              Educational Patterns
            </title>
            <meta
              name="description"
              content="Get ready for the school season with our Back To School embroidery designs at RG Embroidery Designs. Explore fun and educational patterns perfect for students, teachers, and school projects. Start creating today."
            />
          </Helmet>
        );
      case "beauty":
        return (
          <Helmet>
            <title>
              Beauty Embroidery Designs | RG Embroidery Designs - Elegant &
              Stylish Patterns
            </title>
            <meta
              name="description"
              content="Enhance your projects with our Beauty embroidery designs at RG Embroidery Designs. Discover elegant and stylish patterns that add a touch of beauty and sophistication to your creations. Shop our exquisite collection now."
            />
          </Helmet>
        );
      case "beauty-fashion":
        return (
          <Helmet>
            <title>
              Fashion Embroidery Designs | RG Embroidery Designs - Trendy &
              Elegant Patterns
            </title>
            <meta
              name="description"
              content="Discover the latest Fashion embroidery designs at RG Embroidery Designs. Explore trendy and elegant patterns to enhance your garments and accessories. Shop now for stylish and high-quality fashion designs."
            />
          </Helmet>
        );
      case "birds":
        return (
          <Helmet>
            <title>
              Bird Embroidery Designs | RG Embroidery Designs - Soar with
              Beautiful Patterns
            </title>
            <meta
              name="description"
              content="Discover the latest Fashion embroidery designs at RG Embroidery Designs. Explore trendy and elegant patterns to enhance your garments and accessories. Shop now for stylish and high-quality fashion designs."
            />
          </Helmet>
        );
      case "birthday":
        return (
          <Helmet>
            <title>
              Birthday Embroidery Designs | RG Embroidery Designs - Celebrate
              with Unique Patterns
            </title>
            <meta
              name="description"
              content="Celebrate special moments with our Birthday embroidery designs at RG Embroidery Designs. Discover unique and festive patterns perfect for birthday gifts and decorations. Start creating memorable birthday projects today."
            />
          </Helmet>
        );
      case "camping":
        return (
          <Helmet>
            <title>
              Camping Embroidery Designs | RG Embroidery Designs - Adventure
              Awaits
            </title>
            <meta
              name="description"
              content="Embark on your next creative adventure with our Camping embroidery designs at RG Embroidery Designs. Find unique and detailed patterns inspired by the great outdoors. Perfect for camping enthusiasts and nature lovers."
            />
          </Helmet>
        );
      case "celebration":
        return (
          <Helmet>
            <title>
              Celebration Embroidery Designs | RG Embroidery Designs - Joyful
              Patterns for Every Occasion
            </title>
            <meta
              name="description"
              content="Make every occasion special with our Celebration embroidery designs at RG Embroidery Designs. Discover joyful and festive patterns for holidays, parties, and special events. Shop now for designs that celebrate life's moments."
            />
          </Helmet>
        );
      case "christmas":
        return (
          <Helmet>
            <title>
              Christmas Embroidery Designs | RG Embroidery Designs - Festive
              Holiday Patterns
            </title>
            <meta
              name="description"
              content="Get into the holiday spirit with our Christmas embroidery designs at RG Embroidery Designs. Explore festive and intricate patterns perfect for Christmas decorations and gifts. Create memorable holiday projects today."
            />
          </Helmet>
        );
      case "craft-sewing":
        return (
          <Helmet>
            <title>
              Craft & Sewing Embroidery Designs | RG Embroidery Designs -
              Perfect Patterns for Crafters
            </title>
            <meta
              name="description"
              content="Find the perfect patterns for your craft and sewing projects at RG Embroidery Designs. Explore a wide range of detailed and unique embroidery designs tailored for crafting enthusiasts. Enhance your creative projects now."
            />
          </Helmet>
        );
      case "design-bundles":
        return (
          <Helmet>
            <title>
              Embroidery Design Bundles | RG Embroidery Designs - Save with
              Exclusive Bundles
            </title>
            <meta
              name="description"
              content="Save on high-quality embroidery patterns with our exclusive Design Bundles at RG Embroidery Designs. Discover a variety of bundled designs at discounted prices. Shop now and enjoy premium patterns at great value."
            />
          </Helmet>
        );
      case "dogs-pets":
        return (
          <Helmet>
            <title>
              Dog & Pet Embroidery Designs | RG Embroidery Designs - Adorable
              Pet Patterns
            </title>
            <meta
              name="description"
              content="Celebrate your furry friends with our Dog & Pet embroidery designs at RG Embroidery Designs. Find adorable and detailed patterns of dogs, cats, and other pets to enhance your projects. Shop our pet-themed designs today."
            />
          </Helmet>
        );
      case "easter":
        return (
          <Helmet>
            <title>
              Easter Embroidery Designs | RG Embroidery Designs - Celebrate with
              Joyful Patterns
            </title>
            <meta
              name="description"
              content="Celebrate Easter with our joyful embroidery designs at RG Embroidery Designs. Discover festive patterns perfect for Easter decorations, gifts, and crafts. Shop now to create memorable Easter projects."
            />
          </Helmet>
        );
      case "fairy-tails":
        return (
          <Helmet>
            <title>
              Fairy Tale Embroidery Designs | RG Embroidery Designs - Enchanting
              Storybook Patterns
            </title>
            <meta
              name="description"
              content="Bring storybook magic to your projects with our Fairy Tale embroidery designs at RG Embroidery Designs. Find enchanting and whimsical patterns inspired by classic fairy tales. Create charming and imaginative designs today."
            />
          </Helmet>
        );
      case "fantasy":
        return (
          <Helmet>
            <title>
              Fantasy Embroidery Designs | RG Embroidery Designs - Imaginative &
              Magical Patterns
            </title>
            <meta
              name="description"
              content="Dive into a world of imagination with our Fantasy embroidery designs at RG Embroidery Designs. Explore magical and detailed patterns perfect for fantasy lovers. Start your next creative adventure now."
            />
          </Helmet>
        );
      case "farming":
        return (
          <Helmet>
            <title>
              Farming Embroidery Designs | RG Embroidery Designs - Rustic &
              Charming Patterns
            </title>
            <meta
              name="description"
              content="Celebrate the charm of rural life with our Farming embroidery designs at RG Embroidery Designs. Discover rustic and detailed patterns inspired by farm life and agriculture. Perfect for adding a touch of country charm to your projects."
            />
          </Helmet>
        );
      case "fashion":
        return (
          <Helmet>
            <title>
              Fashion Embroidery Designs | RG Embroidery Designs - Stylish &
              Trendy Patterns
            </title>
            <meta
              name="description"
              content="Elevate your style with our Fashion embroidery designs at RG Embroidery Designs. Find stylish and trendy patterns perfect for adding a fashionable touch to your projects. Shop now for high-quality fashion designs."
            />
          </Helmet>
        );
      case "feathers":
        return (
          <Helmet>
            <title>
              Feather Embroidery Designs | RG Embroidery Designs - Delicate &
              Elegant Patterns
            </title>
            <meta
              name="description"
              content="Add elegance to your projects with our Feather embroidery designs at RG Embroidery Designs. Explore delicate and detailed patterns inspired by the beauty of feathers. Shop now for sophisticated feather designs."
            />
          </Helmet>
        );
      case "fishing":
        return (
          <Helmet>
            <title>
              Fishing Embroidery Designs | RG Embroidery Designs - Catch Unique
              Patterns
            </title>
            <meta
              name="description"
              content="Explore our Fishing embroidery designs at RG Embroidery Designs. Discover unique and detailed patterns inspired by fishing and aquatic life. Perfect for fishing enthusiasts and creative projects."
            />
          </Helmet>
        );
      case "flowers":
        return (
          <Helmet>
            <title>
              Flower Embroidery Designs | RG Embroidery Designs - Bloom with
              Beautiful Patterns
            </title>
            <meta
              name="description"
              content="Bring the beauty of nature to your projects with our Flower embroidery designs at RG Embroidery Designs. Discover intricate and stunning patterns inspired by various flowers. Perfect for adding a floral touch to your creations."
            />
          </Helmet>
        );
      case "food":
        return (
          <Helmet>
            <title>
              Food Embroidery Designs | RG Embroidery Designs - Deliciously
              Creative Patterns
            </title>
            <meta
              name="description"
              content="Satisfy your creative appetite with our Food embroidery designs at RG Embroidery Designs. Explore a variety of detailed and fun patterns inspired by different foods. Perfect for kitchen decor and culinary-themed projects."
            />
          </Helmet>
        );
      case "frames":
        return (
          <Helmet>
            <title>
              Farmer Embroidery Designs | RG Embroidery Designs - Celebrate
              Farming Life
            </title>
            <meta
              name="description"
              content="Celebrate the hardworking farmers with our Farmer embroidery designs at RG Embroidery Designs. Discover charming and detailed patterns inspired by farming life and agriculture. Perfect for creating rustic and heartfelt projects."
            />
          </Helmet>
        );
      case "friends-family":
        return (
          <Helmet>
            <title>
              Friends & Family Embroidery Designs | RG Embroidery Designs -
              Heartwarming Patterns
            </title>
            <meta
              name="description"
              content="Celebrate the special bonds with our Friends & Family embroidery designs at RG Embroidery Designs. Find heartwarming and detailed patterns perfect for gifts and family projects. Create meaningful designs that honor your loved ones."
            />
          </Helmet>
        );
      case "halloween":
        return (
          <Helmet>
            <title>
              Halloween Embroidery Designs | RG Embroidery Designs - Spooky &
              Fun Patterns
            </title>
            <meta
              name="description"
              content="Get into the Halloween spirit with our spooky embroidery designs at RG Embroidery Designs. Discover fun and detailed patterns perfect for Halloween decorations and costumes. Create memorable Halloween projects today."
            />
          </Helmet>
        );
      case "holidays":
        return (
          <Helmet>
            <title>
              Holiday Embroidery Designs | RG Embroidery Designs - Celebrate
              with Festive Patterns
            </title>
            <meta
              name="description"
              content="Celebrate various holidays with our festive embroidery designs at RG Embroidery Designs. Explore a wide range of patterns perfect for holiday decorations and gifts. Create joyful and vibrant holiday projects today."
            />
          </Helmet>
        );
      case "insects":
        return (
          <Helmet>
            <title>
              Insect Embroidery Designs | RG Embroidery Designs - Intricate &
              Fascinating Patterns
            </title>
            <meta
              name="description"
              content="Discover the fascinating world of insects with our Insect embroidery designs at RG Embroidery Designs. Find intricate and detailed patterns inspired by various insects. Perfect for adding a unique touch to your projects."
            />
          </Helmet>
        );
      case "july4th":
        return (
          <Helmet>
            <title>
              July 4th Embroidery Designs | RG Embroidery Designs - Patriotic &
              Festive Patterns
            </title>
            <meta
              name="description"
              content="Celebrate Independence Day with our July 4th embroidery designs at RG Embroidery Designs. Discover patriotic and festive patterns perfect for holiday decorations and apparel. Shop now to create memorable July 4th projects."
            />
          </Helmet>
        );
      case "key-fob":
        return (
          <Helmet>
            <title>
              Key Fob Embroidery Designs | RG Embroidery Designs - Personalized
              & Practical Patterns
            </title>
            <meta
              name="description"
              content="Create unique and practical key fobs with our Key Fob embroidery designs at RG Embroidery Designs. Discover personalized and stylish patterns perfect for gifts and personal use. Shop now for high-quality key fob designs."
            />
          </Helmet>
        );
      case "line-art":
        return (
          <Helmet>
            <title>
              Line Art Embroidery Designs | RG Embroidery Designs - Elegant &
              Minimalistic Patterns
            </title>
            <meta
              name="description"
              content="Explore our elegant Line Art embroidery designs at RG Embroidery Designs. Find minimalistic and sophisticated patterns that add a touch of simplicity and beauty to your projects. Perfect for modern and chic creations."
            />
          </Helmet>
        );
      case "monogram":
        return (
          <Helmet>
            <title>
              Monogram Embroidery Designs | RG Embroidery Designs - Personalized
              & Elegant Patterns
            </title>
            <meta
              name="description"
              content="Add a personal touch to your projects with our Monogram embroidery designs at RG Embroidery Designs. Discover elegant and customizable patterns perfect for gifts and home decor. Shop now for beautiful monogram designs."
            />
          </Helmet>
        );
      case "nature":
        return (
          <Helmet>
            <title>
              Nature Embroidery Designs | RG Embroidery Designs - Inspired by
              the Outdoors
            </title>
            <meta
              name="description"
              content="Celebrate the beauty of the outdoors with our Nature embroidery designs at RG Embroidery Designs. Explore intricate patterns inspired by trees, flowers, and wildlife. Perfect for nature-themed projects and decor."
            />
          </Helmet>
        );
      case "ocean":
        return (
          <Helmet>
            <title>
              Ocean Embroidery Designs | RG Embroidery Designs - Dive into
              Beautiful Patterns
            </title>
            <meta
              name="description"
              content="Dive into the beauty of the sea with our Ocean embroidery designs at RG Embroidery Designs. Discover stunning patterns inspired by marine life and seascapes. Perfect for creating ocean-themed projects."
            />
          </Helmet>
        );
      case "ornament":
        return (
          <Helmet>
            <title>
              Ornament Embroidery Designs | RG Embroidery Designs - Elegant &
              Decorative Patterns
            </title>
            <meta
              name="description"
              content="Enhance your projects with our Ornament embroidery designs at RG Embroidery Designs. Find elegant and decorative patterns perfect for adding a touch of sophistication to your creations. Shop now for beautiful ornament designs."
            />
          </Helmet>
        );
      case "outdoor":
        return (
          <Helmet>
            <title>
              Outdoor Embroidery Designs | RG Embroidery Designs -
              Adventure-Inspired Patterns
            </title>
            <meta
              name="description"
              content="Celebrate the great outdoors with our Outdoor embroidery designs at RG Embroidery Designs. Discover adventure-inspired patterns perfect for camping, hiking, and nature projects. Shop now for high-quality outdoor designs."
            />
          </Helmet>
        );
      case "people":
        return (
          <Helmet>
            <title>
              People Embroidery Designs | RG Embroidery Designs - Diverse &
              Detailed Patterns
            </title>
            <meta
              name="description"
              content="Explore our diverse and detailed People embroidery designs at RG Embroidery Designs. Find patterns that celebrate different cultures, professions, and personalities. Perfect for adding a human touch to your projects."
            />
          </Helmet>
        );
      case "plants":
        return (
          <Helmet>
            <title>
              Plant Embroidery Designs | RG Embroidery Designs - Nature-Inspired
              Patterns
            </title>
            <meta
              name="description"
              content="Explore our diverse and detailed People embroidery designs at RG Embroidery Designs. Find patterns that celebrate different cultures, professions, and personalities. Perfect for adding a human touch to your projects."
            />
          </Helmet>
        );
      case "quotes":
        return (
          <Helmet>
            <title>
              Quote Embroidery Designs | RG Embroidery Designs - Inspiring &
              Motivational Patterns
            </title>
            <meta
              name="description"
              content="Find inspiration with our Quote embroidery designs at RG Embroidery Designs. Discover a variety of motivational and heartwarming patterns perfect for gifts and home decor. Shop now for high-quality quote designs."
            />
          </Helmet>
        );
      case "religious":
        return (
          <Helmet>
            <title>
              Religious Embroidery Designs | RG Embroidery Designs -
              Faith-Inspired Patterns
            </title>
            <meta
              name="description"
              content="Celebrate your faith with our Religious embroidery designs at RG Embroidery Designs. Explore a range of intricate patterns inspired by various religious symbols and themes. Perfect for creating meaningful projects."
            />
          </Helmet>
        );
      case "remembrance":
        return (
          <Helmet>
            <title>
              Remembrance Embroidery Designs | RG Embroidery Designs - Honoring
              Memories
            </title>
            <meta
              name="description"
              content="Honor cherished memories with our Remembrance embroidery designs at RG Embroidery Designs. Discover thoughtful and detailed patterns perfect for commemorating special moments and loved ones. Create meaningful remembrance projects today."
            />
          </Helmet>
        );
      case "saying":
        return (
          <Helmet>
            <title>
              Saying Embroidery Designs | RG Embroidery Designs - Expressive &
              Unique Patterns
            </title>
            <meta
              name="description"
              content="Express yourself with our Saying embroidery designs at RG Embroidery Designs. Find unique and expressive patterns featuring various sayings and phrases. Perfect for adding a personal touch to your projects."
            />
          </Helmet>
        );
      case "season-designs":
        return (
          <Helmet>
            <title>
              Seasonal Embroidery Designs | RG Embroidery Designs - Celebrate
              Every Season
            </title>
            <meta
              name="description"
              content="Celebrate every season with our Seasonal embroidery designs at RG Embroidery Designs. Explore a variety of festive and detailed patterns for spring, summer, fall, and winter. Perfect for seasonal projects and decor."
            />
          </Helmet>
        );
      case "sketch-pattern":
        return (
          <Helmet>
            <title>
              Sketch Embroidery Designs | RG Embroidery Designs - Artistic &
              Detailed Patterns
            </title>
            <meta
              name="description"
              content="Unleash your creativity with our Sketch embroidery designs at RG Embroidery Designs. Discover artistic and detailed patterns that mimic the look of hand-drawn sketches. Perfect for adding an artistic touch to your creations."
            />
          </Helmet>
        );
      case "sports":
        return (
          <Helmet>
            <title>
              Sports Embroidery Designs | RG Embroidery Designs - Athletic &
              Dynamic Patterns
            </title>
            <meta
              name="description"
              content="Get in the game with our Sports embroidery designs at RG Embroidery Designs. Discover dynamic and detailed patterns inspired by various sports and activities. Perfect for sports enthusiasts and athletic projects."
            />
          </Helmet>
        );
      case "sports-outdoor":
        return (
          <Helmet>
            <title>
              Sports & Outdoor Embroidery Designs | RG Embroidery Designs -
              Active & Adventurous Patterns
            </title>
            <meta
              name="description"
              content="Explore our Sports & Outdoor embroidery designs at RG Embroidery Designs. Find active and adventurous patterns perfect for outdoor and sports-themed projects. Shop now for high-quality designs that celebrate the great outdoors."
            />
          </Helmet>
        );
      case "thanksgiving":
        return (
          <Helmet>
            <title>
              Thanksgiving Embroidery Designs | RG Embroidery Designs - Festive
              & Heartwarming Patterns
            </title>
            <meta
              name="description"
              content="Celebrate the spirit of Thanksgiving with our embroidery designs at RG Embroidery Designs. Discover festive and heartwarming patterns perfect for Thanksgiving decorations and gifts. Create memorable holiday projects today."
            />
          </Helmet>
        );
      case "transport":
        return (
          <Helmet>
            <title>
              Transport Embroidery Designs | RG Embroidery Designs - Vehicles &
              Travel Patterns
            </title>
            <meta
              name="description"
              content="Travel in style with our Transport embroidery designs at RG Embroidery Designs. Explore detailed patterns featuring various vehicles and modes of transport. Perfect for travel enthusiasts and transport-themed projects."
            />
          </Helmet>
        );
      case "valentines-day":
        return (
          <Helmet>
            <title>
              Valentine's Day Embroidery Designs | RG Embroidery Designs -
              Romantic & Lovely Patterns
            </title>
            <meta
              name="description"
              content="Celebrate love with our Valentine's Day embroidery designs at RG Embroidery Designs. Discover romantic and lovely patterns perfect for gifts and decorations. Create beautiful Valentine's Day projects with our high-quality designs."
            />
          </Helmet>
        );
      // Add more cases as needed
      default:
        return null;
    }
  };

  return (
    <>
      {tag && metaFunc(tag)}
      <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container className="con">
          <div className="d-flex justify-content-between align-items-center">
            <div className="position-relative">
              <h2 className="title">
                {" "}
                {tag
                  ? tag.charAt(0).toUpperCase() + tag.slice(1).toLowerCase()
                  : ""}
              </h2>
              <img
                src="/assets/headdecor.png"
                className="img-fluid best-sellingDecor"
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="Embroideryview-count mb-0">
                Showing {startItem}-{endItem} of {sortedData.length} Results
              </p>
              <Dropdown
                menu={{ items, onClick: handleMenuClick }}
                className="sort-dropdown mx-2"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    Sort By
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
          {paginatedData.length ? (
            <>
              <Card data={paginatedData} />
              <div className="d-flex justify-content-center mt-4">
                <Pagination
                  count={Math.ceil(sortedData.length / ITEMS_PER_PAGE)}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </div>
            </>
          ) : (
            <h2 className="text-center">Oops no item found!</h2>
          )}
        </Container>
      </section>
    </>
  );
};

export default TagProduct;
