import React, { useEffect } from 'react'
import Customeview from '../Customedesgin/Customeview'
import Footer from '../Footer/Footer'

const Custom = () => {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      },[])
    return (
        <>
            <Customeview/>
            <Footer/>
        </>
    )
}

export default Custom
