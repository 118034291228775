import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Routers from './Routing/Routers';

function App() {

  return (
    <>
      <Routers/>
    </>
  );
}

export default App;
