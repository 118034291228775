import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container } from "react-bootstrap";

const Faq = () => {
  return (
    <>
      <section
        className="py-4"
        style={{ backgroundColor: "#FFCEDE" }}
        id="faqs"
      >
        <Container>
          <div className="position-relative">
            <h2 className="title">FAQs</h2>
            <img
              src="/assets/headdecor.png"
              className="img-fluid best-sellingDecor faq-dec"
            />
          </div>
          <div>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                What are digital embroidery designs?
              </AccordionSummary>
              <AccordionDetails>
                Digital embroidery designs are patterns created using
                specialized software, which can be transferred to and used by
                embroidery machines.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                How can I use digital embroidery designs?{" "}
              </AccordionSummary>
              <AccordionDetails>
                You can download the designs and transfer them to your
                embroidery machine via USB or other compatible methods to start
                stitching.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                What file formats do your embroidery designs come in?
              </AccordionSummary>
              <AccordionDetails>
                Our embroidery designs are available in multiple formats,
                including DST, PES, EXP, JEF, and VP3, compatible with most
                machines.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                How do I download my purchased embroidery designs?{" "}
              </AccordionSummary>
              <AccordionDetails>
                After purchase, you'll receive a download link via email. Click
                the link to download your files directly to your device.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Are the designs compatible with all embroidery machines?
              </AccordionSummary>
              <AccordionDetails>
                Our designs are available in multiple formats, ensuring
                compatibility with most embroidery machines.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Can I request custom embroidery designs?
              </AccordionSummary>
              <AccordionDetails>
                Yes, we offer custom design services. Contact us with your
                requirements, and we'll create a unique design for you.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                What is your process for creating custom designs?
              </AccordionSummary>
              <AccordionDetails>
                We discuss your requirements, create a draft design, and
                finalize and deliver the custom design upon your approval.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                How long does it take to receive a custom embroidery design?
              </AccordionSummary>
              <AccordionDetails>
                Depending on complexity, custom designs typically take 3-5
                business days to complete.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Do you offer bulk pricing or discounts for large orders?
              </AccordionSummary>
              <AccordionDetails>
                Yes, we offer significant discounts on bulk orders.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                What should I do if I encounter issues with a downloaded file?
              </AccordionSummary>
              <AccordionDetails>
                Contact us at rgembroiderydesigns@gmail.com, and we'll assist
                you in resolving any issues.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Can I get a refund if I am not satisfied with my purchase?
              </AccordionSummary>
              <AccordionDetails>
                Digital files are normally non-refundable, but we offer refunds
                for genuine cases.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                How can I contact customer support for further assistance?
              </AccordionSummary>
              <AccordionDetails>
                You can reach our customer support via email at
                rgembroiderydesigns@gmail.com.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Are there any usage restrictions on your embroidery designs?
              </AccordionSummary>
              <AccordionDetails>
                Our designs are for personal and commercial use, but you cannot
                resell or distribute the digital files.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Do you offer any promotions or discounts for new customers?
              </AccordionSummary>
              <AccordionDetails>
                We offer discounts, but they are not limited to new customers
                only.{" "}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                What payment methods do you accept?{" "}
              </AccordionSummary>
              <AccordionDetails>
                We accept major credit cards, PayPal, and other secure payment
                methods.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Is my personal and payment information secure on your website?
              </AccordionSummary>
              <AccordionDetails>
                Yes, we use advanced security measures to ensure your personal
                and payment information is protected.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Do you offer any free embroidery designs?
              </AccordionSummary>
              <AccordionDetails>
                Yes, our website has a free collection where you can find many
                designs at no cost.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                How often do you update your design collection?
              </AccordionSummary>
              <AccordionDetails>
                We update our design collection regularly to provide fresh and
                trendy patterns for our customers.
              </AccordionDetails>
            </Accordion>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Faq;
