import React, { useEffect } from 'react'
import Wishist from '../Wishlist/Wishist'
import Footer from '../Footer/Footer'

const Favourite = () => {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      },[])
    return (
        <>
            <Wishist/>
            <Footer/>
        </>
    )
}

export default Favourite
