import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { allProducts } from "../Providers/Apicalls";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { API_URL } from "../Config";
import FreeBees from "./FreeBees";
import { Link } from "react-router-dom";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
}

const FreeBanners = () => {
  const [data, setData] = useState<Product[]>([]);
  const [freeData, setFreeData] = useState<Product | null>(null);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 5,
    speed: 500,
    dots: false,
    arrows: true,
    autoplaySpeed: 2000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          centerPadding: "0px",
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          centerPadding: "0px",
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          centerPadding: "0px",
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          centerPadding: "0px",
        }
      }
    ]
  };

  const getAllProduct = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("getAllProduct"));
      const freeProduct = firstResponse.products.filter(
        (product: Product) => product.isFreebie === true
      );
      setData(freeProduct);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  const handleImageClick = (item: Product) => {
    setFreeData(item);
  };


  return (
    <>
      <section className="py-3" style={{ backgroundColor: "#FFCEDE" }}>
        <Container className="con">
          <div className="slider-container">
            {data.length>2?
            <Slider {...settings}>
              {data.map((item, index) => (
                <div key={index} className="p-2">
                  <img
                    src={`${API_URL}/public/images/${item.image}`}
                    alt={`product ${index + 1}`}
                    style={{ width: "100%", height: "auto", cursor: "pointer" }}
                    onClick={() => handleImageClick(item)}
                  />
                </div>
              ))}
            </Slider>:<h2 className="text-center">Come back later buddy!</h2>}
            <div className='text-center pt-5 pb-2'><Link to="/all-design" style={{textDecoration:"none",color:"#fff"}}><Button className='button-medium' variant="contained">Explore More</Button></Link></div>

          </div>
          {freeData && <FreeBees data={freeData} />}
        </Container>
      </section>
    </>
  );
};

export default FreeBanners;
