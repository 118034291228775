import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { RiArrowDownSFill } from "react-icons/ri";
import { Link } from 'react-router-dom';


const HeaderLg2 = () => {
    const [isDropdown,setIsdropdown]=useState(false)
    return (
        <>
        <section style={{backgroundColor:"#FFEDF3",position:"fixed",zIndex:999,width:"100%",marginBottom:"2rem"}} className='py-3 d-none d-md-block'>
            <Container className='md-container'>
                <div>
                    <div>
                        <div className='d-flex justify-content-around align-items-center mx-5 md-sub' >
                            <div><Link to="/product-category/embroidery-designs" className='navlink'>Embroidery Design</Link></div>
                            <div><Link to="/product-category/print-designs" className='navlink'>Print Design</Link></div>
                            <div><Link to="/custom-digitizing" className='navlink'>Custom Digitizing</Link></div>
                            <div><Link to="/coupon"  className='navlink'>Coupons</Link></div>
                            <div className='position-relative'><p className='navlink' onMouseEnter={()=>setIsdropdown(true)}  onMouseLeave={()=>setIsdropdown(false)} style={{cursor:"pointer"}}>Categories (Tags) <RiArrowDownSFill/></p></div>
                            {isDropdown&&
                            <div className='sub-navlinks' onMouseEnter={()=>setIsdropdown(true)} onMouseLeave={()=>setIsdropdown(false)}>
                                <div className='px-3'>
                                    <ul className='p-0'>
                                        <li><Link to="/product-tag/adventure">Adventure</Link></li>
                                        <li><Link to="/product-tag/animals">Animals</Link></li>
                                        <li><Link to="/product-tag/awareness">Awareness</Link></li>
                                        <li><Link to="/product-tag/back-to-school">Back To School</Link></li>
                                        <li><Link to="/product-tag/beauty">Beauty</Link></li>
                                        <li><Link to="/product-tag/beauty-fashion">Beauty / Fashion</Link></li>
                                        <li><Link to="/product-tag/birds">Birds</Link></li>
                                        <li><Link to="/product-tag/birthday">Birthday</Link></li>
                                        <li><Link to="/product-tag/camping">Camping</Link></li>
                                        <li><Link to="/product-tag/celebration">Celebration</Link></li>
                                        <li><Link to="/product-tag/christmas">Christmas</Link></li>
                                        <li><Link to="/product-tag/craft-sewing">Craft & Sewing</Link></li>
                                        <li><Link to="/product-tag/design-bundles">Design Bundles</Link></li>
                                    </ul>
                                </div>
                                <div className='px-3'>
                                    <ul className='p-0'>
                                        <li><Link to="/product-tag/dogs-pets">Dogs & Pets</Link></li>
                                        <li><Link to="/product-tag/easter">Easter</Link></li>
                                        <li><Link to="/product-tag/fairy-tails">Fairy Tails</Link></li>
                                        <li><Link to="/product-tag/fantasy">Fantasy</Link></li>
                                        <li><Link to="/product-tag/farming">Farming</Link></li>
                                        <li><Link to="/product-tag/fashion">Fashion</Link></li>
                                        <li><Link to="/product-tag/festivals">Festivals</Link></li>
                                        <li><Link to="/product-tag/feathers">Feathers</Link></li>
                                        <li><Link to="/product-tag/fishing">Fishing</Link></li>
                                        <li><Link to="/product-tag/flowers">Flowers</Link></li>
                                        <li><Link to="/product-tag/food">Foods</Link></li>
                                        <li><Link to="/product-tag/frames">Frames</Link></li>
                                    </ul>
                                </div>
                                <div className='px-3'>
                                    <ul className='p-0'>
                                        <li><Link to="/product-tag/friends-family">Friends & Family</Link></li>
                                        <li><Link to="/product-tag/halloween">Halloween</Link></li>
                                        <li><Link to="/product-tag/holidays">Holidays</Link></li>
                                        <li><Link to="/product-tag/insects">Insects</Link></li>
                                        <li><Link to="/product-tag/july4th">July 4th</Link></li>
                                        <li><Link to="/product-tag/key-fob">Key Fob</Link></li>
                                        <li><Link to="/product-tag/line-art">Line Art</Link></li>
                                        <li><Link to="/product-tag/monogram">Monogram</Link></li>
                                        <li><Link to="/product-tag/nature">Nature</Link></li>
                                        <li><Link to="/product-tag/ocean">Ocean</Link></li>
                                        <li><Link to="/product-tag/ornament">Ornament</Link></li>
                                    </ul>
                                </div>
                                <div className='px-3'>
                                    <ul className='p-0'>
                                        <li><Link to="/product-tag/outdoor">Outdoor</Link></li>
                                        <li><Link to="/product-tag/people">People</Link></li>
                                        <li><Link to="/product-tag/plants">Plants</Link></li>
                                        <li><Link to="/product-tag/quotes">Quotes</Link></li>
                                        <li><Link to="/product-tag/religious">Religious</Link></li>
                                        <li><Link to="/product-tag/remembrance">Remembrance</Link></li>
                                        <li><Link to="/product-tag/saying">Saying</Link></li>
                                        <li><Link to="/product-tag/season-designs">Season Designs</Link></li>
                                        <li><Link to="/product-tag/sketch-pattern">Sketch Pattern</Link></li>
                                        <li><Link to="/product-tag/sports">Sports</Link></li>
                                        <li><Link to="/product-tag/sports-outdoor">Sports & Outdoor</Link></li>
                                        <li><Link to="/product-tag/thanksgiving">Thanksgiving</Link></li>
                                        <li><Link to="/product-tag/transport">Transport</Link></li>
                                        <li><Link to="/product-tag/valentines-day">Valentine’s Day</Link></li>
                                    </ul>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </Container>
        </section>  
        </>
    )
}

export default HeaderLg2
