import React, { useEffect } from 'react'
import Privacy from '../Home/Privacy'
import Footer from '../Footer/Footer'

const PrivacyPolicy = () => {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      },[])
  return (
    <>
      <Privacy/>
      <Footer/>
    </>
  )
}

export default PrivacyPolicy
