import axios from "axios";
import { API_URL } from "../Config";

axios.defaults.withCredentials = true;

const getAuthToken = () => {
  return localStorage.getItem("rgtoken");
};

const getHeaders = () => {
  const token = getAuthToken();
  // console.log(token,"token");
  return {
    "Content-Type": "application/json",
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };
};

// SignUp
export const signupUser = async (endpoint: string, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

// Login
export const loginUser = async (endpoint: string, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//GetMe
export const getMe = async (endpoint: string): Promise<any> => {
  try {
    const response = await axios.get(new URL(endpoint, API_URL).toString(), {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//ForgetPassword and For posting datas
export const forgetPass = async (endpoint: string, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};


export const capturepay = async (endpoint: string): Promise<any> => {
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//AllProducts
export const allProducts = async (endpoint: string): Promise<any> => {
  try {
    const response = await axios.get(new URL(endpoint, API_URL).toString(), {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch data:", error);
    throw error;
  }
};

//PatchUser
export const patchuser = async (
  endpoint: string,
  userId: any,
  data: any
): Promise<any> => {
  try {
    const response = await axios.patch(
      new URL(endpoint + `/${userId}`, API_URL).toString(),
      data,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//Delete
export const deleteitem = async (endpoint: string, data: any): Promise<any> => {
  try {
    const response = await axios({
      method: "delete",
      url: new URL(endpoint, API_URL).toString(),
      headers: getHeaders(),
      data: data,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to delete data:", error);
    throw error;
  }
};

//Patch data
export const patchData = async (
  endpoint: string,
  productId: any
): Promise<any> => {
  try {
    const response = await axios.patch(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

// getbySlug
export const getSlugProduct = async (
  endpoint: string,
  slug: any
): Promise<any> => {
  try {
    const response = await axios.get(
      new URL(endpoint + `/${slug}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//Get reviews by productID
export const getProductreview = async (
  endpoint: string,
  id: any
): Promise<any> => {
  try {
    const response = await axios.get(
      new URL(endpoint + `?product=${id}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//postCustom
export const postCustom = async (endpoint: string, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//Patch formData
export const patchCustom = async (
  endpoint: string,
  id: any,
  data: any
): Promise<any> => {
  try {
    const response = await axios.patch(
      new URL(endpoint + `/${id}`, API_URL).toString(),
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};
