import React, { useEffect } from 'react'
import FreeBanners from '../FreeBees/FreeBanners'
import FreeBees from '../FreeBees/FreeBees'
import Footer from '../Footer/Footer'

const Free = () => {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      },[])
    return (
        <>
            <FreeBanners/>
            <Footer/>
        </>
    )
}

export default Free
