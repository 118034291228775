import React, { useEffect, useState } from "react";
import { FaStar, FaRegHeart, FaEye, FaHeart } from "react-icons/fa";
import { AiOutlineShoppingCart, AiFillShopping } from "react-icons/ai";
import { IoIosShareAlt, IoMdCloseCircleOutline } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Modal, Rating, Table } from "@mui/material";
import { Container } from "react-bootstrap";
import { API_URL } from "../Config";
import { ApiEndPoints } from "../Providers/api_endpoints";
import {
  deleteitem,
  forgetPass,
  getMe,
  loginUser,
  patchuser,
} from "../Providers/Apicalls";
import { message } from "antd";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
}

interface CardProps {
  data: Product[];
}

type UserData = {
  name: string;
  phone: number | null;
  email: string;
  role: string;
  orders: [];
  cart: string[];
  wishlist: string[];
  wallet: number | null;
  _id: string;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Card: React.FC<CardProps> = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [userData, setUserData] = useState<UserData>({
    name: "",
    phone: null,
    email: "",
    role: "",
    orders: [],
    cart: [],
    wishlist: [],
    wallet: null,
    _id: "",
  });

  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px", // Set the font size
        fontWeight: "bold", // Set the font weight
      },
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        fontSize: "16px", // Set the font size
        fontWeight: "bold", // Set the font weight
      },
    });
  };

  const handleOpen = (product: Product) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProduct(null);
  };

  const handleViewProduct = (key: any) => {
    if (key.category === "embroidery design") {
      navigate(`/embroidery-design/${key.slug}`);
    } else if (key.category === "print design") {
      navigate(`/print-design/${key.slug}`);
    } else {
      navigate("/freebies");
    }
  };

  const getUser = async () => {
    try {
      const getMeuser = await getMe(ApiEndPoints("getMe"));

      // Set the user data to state
      setUserData(getMeuser.user);

      // Extract wishlist IDs from the user data
      const userWishlist = getMeuser.user.wishlist.map((item: any) => item);

      // Check if products in the current view are in the wishlist
      data.forEach((product) => {
        const isInWishlist = userWishlist.includes(product._id);
        isProductInWishlist(isInWishlist);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const isProductInWishlist = (productId: string) => {
    return userData.wishlist.some((item) => item === productId);
  };

  const isProductInCart = (productId: string) => {
    return userData.cart.some((item) => item === productId);
  };

  const handleWishlist = async (
    product: Product,
    action: "wishlist" | "cart"
  ) => {
    try {
      const payload = { product: product._id };

      if (action === "wishlist") {
        if (isProductInWishlist(product._id!)) {
          // Remove from wishlist
          await deleteitem(ApiEndPoints("removeFromWishlist"), payload);
        } else {
          // Add to wishlist
          await forgetPass(ApiEndPoints("addtoWishlist"), payload);
        }

        // Update local state
        setUserData((prevState) => {
          const updatedWishlist = isProductInWishlist(product._id!)
            ? prevState.wishlist.filter((id) => id !== product._id)
            : [...prevState.wishlist, product._id!];
          return { ...prevState, wishlist: updatedWishlist };
        });
      } else if (action === "cart") {
        if (isProductInCart(product._id!)) {
          // Remove from cart
          await deleteitem(ApiEndPoints("removeFromCart"), payload);
        } else {
          // Add to cart
          await forgetPass(ApiEndPoints("addtoCart"), payload);
        }

        // Update local state
        setUserData((prevState) => {
          const updatedCart = isProductInCart(product._id!)
            ? prevState.cart.filter((id) => id !== product._id)
            : [...prevState.cart, product._id!];
          return { ...prevState, cart: updatedCart };
        });
      }

      successmessage(
        `Product ${
          action === "wishlist" ? "wishlist" : "cart"
        } updated successfully.`
      );
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  const shareData = {
    title: "RG Designs",
    text: "Buy all types of design at one place",
    url: window.location.href,
  };
  const handleShare = async () => {
    try {
      await navigator.share(shareData);
    } catch (err: any) {
      message.error(err.response.message);
    }
  };

  return (
    <>
      {contextHolder}
      <div className="row mx-0">
        {data.map((val, index) => (
          <div className="col-lg-3 col-md-6 col-sm-6 col-6" key={index}>
            <div className="text-center position-relative card-hover py-3">
              <img
                src={`${API_URL}/public/images/${val.image}`}
                alt="product"
                className="img-fluid card-img"
                onClick={() => handleViewProduct(val)}
              />
              <div className="product-icon">
                <div className="d-flex justify-content-center align-items-center">
                  <div>
                    <div className="icons">
                      {isProductInWishlist(val._id!) ? (
                        <FaHeart
                          onClick={() => handleWishlist(val, "wishlist")}
                          style={{ color: "red" }}
                        />
                      ) : (
                        <FaRegHeart
                          onClick={() => handleWishlist(val, "wishlist")}
                        />
                      )}
                    </div>
                    <span className="count">{val.wishlistCount}</span>
                  </div>
                  <div>
                    <div className="icons">
                      {isProductInCart(val._id!) ? (
                        <AiFillShopping
                          onClick={() => handleWishlist(val, "cart")}
                          style={{ color: "green" }}
                        />
                      ) : (
                        <AiOutlineShoppingCart
                          onClick={() => handleWishlist(val, "cart")}
                        />
                      )}
                    </div>
                    <span className="count">{val.cartCount}</span>
                  </div>
                  <div>
                    <div className="icons" onClick={() => handleOpen(val)}>
                      <FaEye />
                    </div>
                    <span className="count" style={{ visibility: "hidden" }}>
                      0
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-details">
                <h6 className="mb-0 py-1">
                  {val.category
                    ? val.category.charAt(0).toUpperCase() +
                      val.category.slice(1).toLowerCase()
                    : "Unknown Category"}
                </h6>

                <p className="mb-0">{val.name}</p>
                <p className="mb-0">
                  <span className="card-price">$ {val.price?.toFixed(2)}</span>{" "}
                  |{" "}
                  <span className="d-inline-flex justify-content-center align-items-center">
                    {val.ratingsAverage}
                    <FaStar style={{ color: "#D327A1" }} />
                  </span>
                </p>
              </div>
            </div>
          </div>
        ))}

        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <section className="py-4" style={{ backgroundColor: "#FFEDF3" }}>
              <Container>
                <div className="text-end py-2">
                  <IoMdCloseCircleOutline
                    style={{ fontSize: "1.5rem", cursor: "pointer" }}
                    onClick={handleClose}
                  />
                </div>
                {selectedProduct && (
                  <div className="row">
                    <div className="col-lg-5 col-md-6 col-sm-12">
                      <div>
                        <img
                          src={`${API_URL}/public/images/${selectedProduct.image}`}
                          className="img-fluid "
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-7 col-md-6 col-sm-12"
                      style={{ overflowY: "auto" }}
                    >
                      <div className="py-3 px-4">
                        <div className="d-flex justify-content-start align-items-center">
                          <h3 className="embroidery-title">
                            {selectedProduct.name
                              ? selectedProduct.name
                              : "N/A"}
                          </h3>
                          <div className="heart-icon">
                            {isProductInWishlist(selectedProduct._id!) ? (
                              <FaHeart
                                onClick={() =>
                                  handleWishlist(selectedProduct, "wishlist")
                                }
                                style={{ color: "red" }}
                              />
                            ) : (
                              <FaRegHeart
                                onClick={() =>
                                  handleWishlist(selectedProduct, "wishlist")
                                }
                              />
                            )}
                          </div>
                        </div>
                        <div className="d-flex justify-content-start align-items-center flex-wrap">
                          <Rating
                            name="read-only"
                            value={
                              selectedProduct.ratingsAverage
                                ? selectedProduct.ratingsAverage
                                : 0
                            }
                            readOnly
                          />
                          <p className="embroidery-rating mb-0">
                            {selectedProduct.ratingsAverage
                              ? selectedProduct.ratingsAverage
                              : "-"}{" "}
                            ratings *{" "}
                            {selectedProduct.reviewsCount
                              ? selectedProduct.reviewsCount
                              : "-"}{" "}
                            Reviews{" "}
                            <span className="embroidery-totalbuyers">
                              ({" "}
                              {selectedProduct.buyersCount
                                ? selectedProduct.buyersCount
                                : "-"}{" "}
                              Buyers)
                            </span>
                          </p>
                        </div>
                        <p className="embroidery-Category py-2">
                          Category :{" "}
                          {selectedProduct.category
                            ? selectedProduct.category
                            : "-"}
                        </p>
                        <div className="d-flex justify-content-between flex-wrap align-items-center mb-2">
                          <div>
                            <h3 className="embroidery-price mb-0">
                              ${" "}
                              {selectedProduct.price
                                ? selectedProduct.price
                                : "-"}
                            </h3>
                          </div>
                          <div>
                            {isProductInCart(selectedProduct._id!) ? (
                              <Button
                                onClick={() =>
                                  handleWishlist(selectedProduct, "cart")
                                }
                                variant="contained"
                                className="button-medium"
                              >
                                Added
                              </Button>
                            ) : (
                              <Button
                                onClick={() =>
                                  handleWishlist(selectedProduct, "cart")
                                }
                                variant="contained"
                                className="button-medium"
                              >
                                Add To Cart
                              </Button>
                            )}

                            <Button
                              variant="contained"
                              className="share-btn mx-3"
                              onClick={handleShare}
                            >
                              <IoIosShareAlt />
                            </Button>
                          </div>
                        </div>
                        <div>
                          {selectedProduct.category?.toLocaleLowerCase() ===
                          "embroidery design" ? (
                            <Table className="view-table">
                              <tbody>
                                <tr>
                                  <td>Sizes (Inches)</td>
                                  <td>
                                    {selectedProduct.sizes
                                      ? selectedProduct.sizes
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Stitch Count</td>
                                  <td>
                                    {selectedProduct.stitchCount
                                      ? selectedProduct.stitchCount
                                      : "-"}
                                  </td>
                                </tr>
                                {/* <tr>
                                  <td>Colors</td>
                                  <td>
                                    {selectedProduct.colors
                                      ? selectedProduct.colors
                                      : "N/A"}
                                  </td>
                                </tr> */}
                                <tr>
                                  <td>Formats</td>
                                  <td>
                                    {selectedProduct.formats
                                      ? selectedProduct.formats
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Quantity</td>
                                  <td>
                                    {selectedProduct.sizes
                                      ? selectedProduct.sizes
                                      : 0}{" "}
                                    Size
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          ) : (
                            <>
                              <h2 className="product-desc">
                                Product Description
                              </h2>
                              <p>{selectedProduct.description}</p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Container>
            </section>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Card;
