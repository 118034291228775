import React, { useEffect } from 'react'
import ReturnFunds from '../Home/ReturnFunds'
import Footer from '../Footer/Footer'

const ReturnFundsPolicies = () => {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      },[])
    return (
        <>
            <ReturnFunds/>
            <Footer/>
        </>
    )
}

export default ReturnFundsPolicies
