import React, { useEffect } from 'react'
import EmbroideryDesign from '../EmbroideryDesign/EmbroideryDesign'
import RelatedProducts from '../EmbroideryDesign/RelatedProducts'
import Footer from '../Footer/Footer'

const Embroideryview = () => {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      },[])
    return (
        <>
            <EmbroideryDesign/>
            <RelatedProducts/>
            <Footer/>
        </>
    )
}

export default Embroideryview
