import { Button, Rating } from "@mui/material";
import React from "react";
import { Container, Table } from "react-bootstrap";
import { FaRegHeart } from "react-icons/fa";
import { IoIosShareAlt } from "react-icons/io";

const PrintDetails = () => {
  return (
    <>
      <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container className="con">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12 product-heigth">
              <div>
                <img
                  src="/assets/productview.png"
                  className="img-fluid embroidery-img"
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 product-heigth">
              <div className="embroidery-content py-3 px-4">
                <div className="d-flex justify-content-start align-items-center">
                  <h3 className="embroidery-title">Side By Side Sisters</h3>
                  <div className="heart-icon">
                    <FaRegHeart />
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center flex-wrap">
                  <Rating name="read-only" value={3} readOnly />
                  <p className="embroidery-rating mb-0">
                    20 ratings * 50 Reviews <span className="embroidery-totalbuyers">( 50k Buyers)</span>
                  </p>
                </div>
                <p className="embroidery-Category py-2">Category : Print Designs</p>
                <div className="d-flex justify-content-between flex-wrap align-items-center mb-5">
                  <div>
                    <h3 className="embroidery-price mb-0">$ 3.49</h3>
                  </div>
                  <div className="d-flex">
                    <Button variant="contained" className="button-medium">Add To Cart</Button>
                    <Button variant="contained" className="share-btn mx-3">
                      <IoIosShareAlt />
                    </Button>
                  </div>
                </div>
                <div className="product-description pb-2">
                    <h3>Product Description</h3>
                    <p className="mb-0">
                    Side by side Sisters –  RGS 015
                    </p>
                    <p className="mb-0">SVG Cut file for circuit machines</p>
                    <p className="mb-0">This product is for INSTANT DOWNLOAD, Contains the SVG & PNG files in High-resolution. Can be printed, sublimation, DTG, Iron on Transfer on anything such as T-shirt, Mug, Sign, Stickers.</p>
                    <p className="mb-0">You are not allowed to sell, share, give away or trade.</p>
                    <p className="mb-0">We assure 100% Satisfaction guarantee, if you are not satisfied with our orders we have a refund policy. Please message us</p>
                    <p className="mb-0">Please help us by reviewing and sharing the sew out after purchasing our products.</p>
                    <p className="mb-0">After purchasing our products kindly support us by reviewing and sharing the sew out thank you.</p>
                </div>
                <div className="customer-reviews">
                    <h3>Reviews</h3>
                    <h5>Karthi . <span className="review-date">01-04-2014</span></h5>
                    <Rating name="read-only" value={3} readOnly />
                    <p className="review-date">A product description is a form of marketing copy used to describe and explain the benefits of your product. In other words, it provides all the information and details of your product on your ecommerce site. These product details can be one sentence, a short paragraph or bulleted.</p>
                </div>
                <div className="text-center py-3">
                    <Button variant="contained" className="button-medium">See More</Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default PrintDetails;
