import React, { useEffect, useState } from "react";
import { Button, Rating } from "@mui/material";
import { Container, Table } from "react-bootstrap";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { IoIosShareAlt } from "react-icons/io";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { deleteitem, forgetPass, getMe, getProductreview } from "../Providers/Apicalls";
import { API_URL } from "../Config";
import { message } from "antd";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
}

interface Review {
  _id: string;
  user: {
    _id: string;
    name: string;
  };
  rating: number;
  review: string;
  date: string;
}

type UserData = {
  name: string;
  phone: number | null;
  email: string;
  role: string;
  orders: [];
  cart: Product[];
  wishlist: Product[];
  wallet: number | null;
  _id: string;
};

const FreeBees = ({ data }: { data: Product }) => {
  const [productData, setProductData] = useState<Product | null>(null);
  const [reviewData, setReviewData] = useState<Review[]>([]);
  const [visibleReviews, setVisibleReviews] = useState<Review[]>([]);
  const [reviewsShown, setReviewsShown] = useState<number>(2);
  const [userData, setUserData] = useState<UserData>({
    name: "",
    phone: null,
    email: "",
    role: "",
    orders: [],
    cart: [],
    wishlist: [],
    wallet: null,
    _id: "",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const handleReviewProduct = async () => {
    try {
      const fetchReviews = await getProductreview(ApiEndPoints("getreviewbyID"), data._id);
      setReviewData(fetchReviews.reviews);
      setVisibleReviews(fetchReviews.reviews.slice(0, 2)); // Show initial 2 reviews
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  const handleSeeMore = () => {
    const nextReviews = reviewsShown + 10;
    setVisibleReviews(reviewData.slice(0, nextReviews));
    setReviewsShown(nextReviews);
  };

  const getUser = async () => {
    try {
      const getMeuser = await getMe(ApiEndPoints("getMe"));
      setUserData(getMeuser.user);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data && data._id) {
      setProductData(data);
      handleReviewProduct();
    }
  }, [data]);

  useEffect(() => {
    getUser();
  }, []);

  const isProductInWishlist = (productId: string) => {
    return userData.wishlist.some((item) => item._id === productId);
  };

  const isProductInCart = (productId: string) => {
    return userData.cart.some((item) => item._id === productId);
  };

  const handleWishlist = async (product: Product, action: "wishlist" | "cart") => {
    try {
      const payload = { product: product._id };

      if (action === "wishlist") {
        if (isProductInWishlist(product._id!)) {
          await deleteitem(ApiEndPoints("removeFromWishlist"), payload);
          setUserData((prevState) => ({
            ...prevState,
            wishlist: prevState.wishlist.filter((item) => item._id !== product._id),
          }));
        } else {
          await forgetPass(ApiEndPoints("addtoWishlist"), payload);
          setUserData((prevState) => ({
            ...prevState,
            wishlist: [...prevState.wishlist, product],
          }));
        }
      } else if (action === "cart") {
        if (isProductInCart(product._id!)) {
          await deleteitem(ApiEndPoints("removeFromCart"), payload);
          setUserData((prevState) => ({
            ...prevState,
            cart: prevState.cart.filter((item) => item._id !== product._id),
          }));
        } else {
          await forgetPass(ApiEndPoints("addtoCart"), payload);
          setUserData((prevState) => ({
            ...prevState,
            cart: [...prevState.cart, product],
          }));
        }
      }

      successmessage(`Product ${action === "wishlist" ? "wishlist" : "cart"} updated successfully.`);
    } catch (error: any) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        errormessage(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  if (!productData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {contextHolder}
      <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container className="con">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12 product-heigth">
              <div>
                <img
                  src={`${API_URL}/public/images/${productData.image}`}
                  alt={productData.name}
                  className="img-fluid embroidery-img"
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 product-heigth">
              <div className="embroidery-content py-3 px-4">
                <div className="d-flex justify-content-start align-items-center">
                  <h3 className="embroidery-title">{productData.name}</h3>
                  <div className="heart-icon">
                    {isProductInWishlist(productData._id!) ? (
                      <FaHeart
                        onClick={() => handleWishlist(productData, "wishlist")}
                        style={{ color: "red" }}
                      />
                    ) : (
                      <FaRegHeart
                        onClick={() => handleWishlist(productData, "wishlist")}
                      />
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center flex-wrap">
                  <Rating name="read-only" value={productData.ratingsAverage || 0} readOnly />
                  <p className="embroidery-rating mb-0">
                    {productData.reviewsCount || 0} ratings *{" "}
                    {productData.reviewsCount || 0} Reviews{" "}
                    <span className="embroidery-totalbuyers">
                      ({productData.buyersCount || "0"} Buyers)
                    </span>
                  </p>
                </div>
                <p className="embroidery-Category py-2">
                  Category : {productData.category || "N/A"}
                </p>
                <div className="d-flex justify-content-between flex-wrap align-items-center mb-5">
                  <div>
                    <h3 className="embroidery-price mb-0">
                      ${"0.00"}
                    </h3>
                  </div>
                  <div>
                    {isProductInCart(productData._id!) ? (
                      <Button
                        onClick={() => handleWishlist(productData, "cart")}
                        variant="contained"
                        className="button-medium"
                      >
                        Added
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleWishlist(productData, "cart")}
                        variant="contained"
                        className="button-medium"
                      >
                        Add To Cart
                      </Button>
                    )}
                    <Button variant="contained" className="share-btn mx-3">
                      <IoIosShareAlt />
                    </Button>
                  </div>
                </div>
                <div>
                  {productData.category === "embroidery design" ? (
                    <>
                      <Table className="view-table">
                        <tbody>
                          <tr>
                            <td>Sizes (Inches)</td>
                            <td>{productData.sizes || "N/A"}</td>
                          </tr>
                          <tr>
                            <td>Stitch Count</td>
                            <td>{productData.stitchCount || "N/A"}</td>
                          </tr>
                          <tr>
                            <td>Colors</td>
                            <td>{productData.colors || "N/A"}</td>
                          </tr>
                          <tr>
                            <td>Formats</td>
                            <td>{productData.formats || "N/A"}</td>
                          </tr>
                          <tr>
                            <td>Quantity</td>
                            <td>{productData.quantity || "N/A"}</td>
                          </tr>
                        </tbody>
                      </Table>
                      <div>
                        <h2 className="product-desc">Product Description</h2>
                        <p>{productData.description}</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <h2 className="product-desc">Product Description</h2>
                      <p>{productData.description}</p>
                    </>
                  )}
                </div>
                <h3 className="review-title">Product Reviews</h3>
                {visibleReviews.length > 0 ? (
                  <div>
                    {visibleReviews.map((review) => (
                      <div key={review._id}>
                        <h5>
                          {review.user.name}{" "}
                          <span className="review-date">
                            {new Date(review.date).toLocaleDateString()}
                          </span>
                        </h5>
                        <Rating name="read-only" value={review.rating} readOnly />
                        <p className="review-text">{review.review}</p>
                      </div>
                    ))}
                    {reviewData.length > reviewsShown && (
                      <Button onClick={handleSeeMore}>See More</Button>
                    )}
                  </div>
                ) : (
                  <p>No reviews available.</p>
                )}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default FreeBees;
