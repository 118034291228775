import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { allProducts } from "../Providers/Apicalls";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { API_URL } from "../Config";
import { Margin } from "@mui/icons-material";

// Define the banner type
interface Banner {
  heading: string;
  content: string;
  image: string;
}



const BannerComponent: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const settingss = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow:false,
    autoplaySpeed: 2000,
  };


const mobileBanner = [
  "/assets/slide1.png",
  "/assets/slide2.png",
  "/assets/slide3.png",
  "/assets/slide4.png",
  "/assets/slide5.png",
];


  // State to store the banner data (with the defined Banner type)
  const [banners, setBanners] = useState<Banner[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Fetch banner data from API  getCustombanner
  const getAllProduct = async () => {
    try {
      const response = await allProducts(ApiEndPoints("getAllbanner"));
      await getAllcustomBanner()
      const bannerData = response?.heroPages || [];
      setBanners(bannerData);
    } catch (error) {
      console.error(error);
    }
  };

  const [customBanner,setCustomBanner]=useState<any>([])
  const getAllcustomBanner = async () => {
    try {
      const response = await allProducts(ApiEndPoints("getCustombanner"));
      const validBanners = response.customBanners.filter((banner: any) => {
        const today = new Date();
        const expiryDate = new Date(banner.expiryDate);
        return expiryDate > today; 
      });
      setCustomBanner(validBanners)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
  
    getAllProduct();
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [banners.length]);

  

  return (
    <>
    {customBanner.length>=2? <Slider {...settingss}>
      {customBanner.map((val:any)=>(
      <div>
       <img className="img-fluid" src={`${API_URL}/public/images/${val.image}`} style={{width:"100%"}}/>
      </div>))}
    </Slider>:
    <>
      <section
        className="banner-section d-none d-sm-block"
        style={{ overflow: "hidden" }}
      >
        <Container>
          <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-12 d-flex justify-content-start align-items-center mt-5">
              <div>
                {banners.length > 0 && (
                  <>
                    <h1 className="banner-head">
                      {banners[currentImageIndex]?.heading}
                    </h1>
                    <p className="banner-para">
                      {banners[currentImageIndex]?.content}
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="banner-container align-middle d-flex justify-content-center align-items-center">
                {banners.map((banner, index) => (
                  <img
                    key={index}
                    className={`img-fluid banner-img ${
                      index === currentImageIndex ? "active" : ""
                    }`}
                    src={`${API_URL}/public/images/${banner.image}`}
                    alt="Banner"
                    style={{
                      display: index === currentImageIndex ? "block" : "none",
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section style={{ overflow: "hidden" }} className="mobile-banner">
        <div className="slider-container p-0">
        <Slider {...settings}>
            {mobileBanner.map((val, index) => (
              <div key={index}>
                <img src={val} className="img-fluid" style={{height:"auto"}}/>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>}
    </>
  );
};

export default BannerComponent;
