import React, { useEffect } from 'react'
import Footer from '../Footer/Footer'
import TagProduct from '../Alldesign/TagProduct';

const ProdByTag = () => {
  useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  },[])
    return (
        <>
          <TagProduct/>
          <Footer/>  
        </>
    )
}

export default ProdByTag;
