import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { IoIosSearch } from "react-icons/io";
import { FaBoxOpen } from "react-icons/fa6";
import { CgProfile } from "react-icons/cg";
import { IoMdHeart } from "react-icons/io";
import { Container, Offcanvas } from "react-bootstrap";
import { IoCartSharp } from "react-icons/io5";
import { HiMiniInboxArrowDown } from "react-icons/hi2";
import { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { RxHamburgerMenu } from "react-icons/rx";
import { Button, Modal } from "@mui/material";
import { GiCancel } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker, Input, message, Radio, Space } from "antd";
import {
  forgetPass,
  getMe,
  loginUser,
  signupUser,
} from "../Providers/Apicalls";
import { ApiEndPoints } from "../Providers/api_endpoints";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  borderRadius: 4,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "white",
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "45ch",
    },
  },
}));

export default function HeaderLg() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error"></Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <FaBoxOpen />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        ></IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  type Signupdata = {
    name: string;
    email: string;
    password: string;
    role: string;
    phone: number | null;
  };

  type UserData = {
    name: string;
    phone: number | null;
    email: string;
    role: string;
    orders: [];
    cart: [];
    wishlist: [];
    wallet: number | null;
    _id: string;
  };

  const [selected, setSelected] = useState("IN");
  const [openSignup, setOpenSignup] = useState(false);
  const [openlogin, setOpenlogin] = useState(false);
  const [openforgetpass, setOpenforgetpass] = useState(false);
  const [openresetpass,setOpenResetPass]=useState(false)
  const [openProfile, setOpenProfile] = useState(false);
  const [signupData, setsignupData] = useState<Signupdata>({
    name: "",
    email: "",
    password: "",
    phone: null,
    role: "user",
  });

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [resetPass,setResetPass]=useState({
    password:"",
    conformPass:""
  })

  const [userData, setUserData] = useState<UserData>({
    name: "",
    phone: null,
    email: "",
    role: "",
    orders: [],
    cart: [],
    wishlist: [],
    wallet: null,
    _id: "",
  });

  const [forgetEmail, setForgetEmail] = useState("");

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px", // Set the font size
        fontWeight: "bold", // Set the font weight
      },
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        fontSize: "16px", // Set the font size
        fontWeight: "bold", // Set the font weight
      },
    });
  };

  const handleSignupOpen = () => {
    const token=localStorage.getItem("rgtoken")
    if (token) {
      setShow(false)
      setOpenProfile(true);
    } else {
      setShow(false)
      setOpenSignup(true);
    }
  };
  const handleSignupClose = () => setOpenSignup(false);

  const handleAlreadyuser = () => {
    setOpenSignup(false);
    setOpenlogin(true);
  };

  const handlenavcreateAccount = () => {
    setOpenlogin(false);
    setOpenSignup(true);
  };

  const handlenavForgetpass = () => {
    setOpenlogin(false);
    setOpenforgetpass(true);
  };

  const handleforgetpassCancel = () => {
    setOpenforgetpass(false);
    setOpenlogin(true);
  };

  const navigate = useNavigate();
  const handlenavOrder = () => {
    navigate("/my-orders");
  };
  const handlenavFav = () => {
    if(userData.email){
    if (userData.wishlist.length) {
      navigate("/my-wishlist");
    }
    else{
      errormessage("No items in wishlist!")
    }}
    else{
      errormessage("Please login!")
    }
  };

  const handlenavCart = () => {
    if(userData.email){
    if (userData.cart.length) {
      navigate("/shopping-cart");
    }
    else{
      errormessage("No items in cart!")
    }
  }else{
    errormessage("Please login!")
  }
  };

  const handlenavHome = () => {
    navigate("/");
  };

  const handleLogout = () => {
    localStorage.removeItem("rgtoken");
    setOpenProfile(false);
    setOpenSignup(true);
    window.location.reload();
  };

  //GetMe
  const getUser = async () => {
    try {
      const getMeuser = await getMe(ApiEndPoints("getMe"));
      setUserData(getMeuser.user);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getUser();

    // Set up interval to call getUser every 1000ms (1 second)
    // const intervalId = setInterval(() => {
    //   getUser();
    // }, 1000);

    // // Clear interval on component unmount
    // return () => clearInterval(intervalId);
  }, []);

  //handleSignup
  const handleSignup = async (e: any) => {
    e.preventDefault();

    if (
      signupData.email === "" ||
      signupData.password === "" ||
      signupData.name === ""
    ) {
      errormessage("All fields required!");
    } else if (signupData.password.length < 6) {
      errormessage("Password must be greater than 6 letters");
    } else {
      try {
        const result = await signupUser(ApiEndPoints("signup"), signupData);
        successmessage("Signup success");
        handleAlreadyuser();
        setsignupData({
          name: "",
          email: "",
          password: "",
          phone: null,
          role: "user",
        });
      } catch (error: any) {
        console.log(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errormessage(error.response.data.message);
        } else {
          errormessage("An unexpected error occurred. Please try again.");
        }
      }
    }
  };

  //handleSigniN

  const [resettoken,setResetToken]=useState("")

  const handleSigniN = async (e: any) => {
    e.preventDefault();

    if (loginData.email === "" || loginData.password === "") {
      errormessage("Need email and password");
    } else if (loginData.password.length < 6) {
      errormessage("Check password !");
    } else {
      try {
        const result = await loginUser(ApiEndPoints("login"), loginData);
        if (result.token !== null) {
          // && result.role === "user"
          localStorage.setItem("rgtoken", result.token);
          window.dispatchEvent(new Event("storage"));
          successmessage("Login success");
          getUser();
          window.location.reload();
        } else {
          errormessage("Please login as user...");
        }

        // getallOrders()
      } catch (error: any) {
        console.log(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errormessage(error.response.data.message);
        } else {
          errormessage("An unexpected error occurred. Please try again.");
        }
      }
    }
  };

  const [passMessage,setPassMessage]=useState("")
  const [isloading,setIsLoading]=useState(false)
  const handleforgetPassword = async (e: any) => {
    e.preventDefault()
      try {
        setIsLoading(true)
        const result = await forgetPass(ApiEndPoints("forgetPass"), {email:forgetEmail});
        message.success(result.message)
        // setOpenResetPass(true)
        setPassMessage(result.message)

      } catch (error: any) {
        console.log(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errormessage(error.response.data.message);
        } else {
          errormessage("An unexpected error occurred. Please try again.");
        }
      }finally{
        setIsLoading(false)
      }
  };


  //Search
  const handleSearch = (e: any) => {
    const value = e.target.value;
    navigate(`/search?query=${value}`);
  };



  //ResetPassword
  // const handleupdatePassword = async (e: any) => {
  //   e.preventDefault()
  //     try {
  //       const result = await forgetPass(ApiEndPoints("resetPass"),);
  //       setOpenResetPass(true)
  //     } catch (error: any) {
  //       console.log(error);
  //       if (
  //         error.response &&
  //         error.response.data &&
  //         error.response.data.message
  //       ) {
  //         errormessage(error.response.data.message);
  //       } else {
  //         errormessage("An unexpected error occurred. Please try again.");
  //       }
  //     }
    
  // };

  return (
    <div style={{ marginBottom: "5rem" }}>
      {contextHolder}
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Container>
            <Toolbar>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleShow}
                  color="inherit"
                >
                  <RxHamburgerMenu style={{ color: "#D357A7" }} />
                </IconButton>
              </Box>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "block", sm: "block" } }}
              >
                <img
                  className="img-fluid py-2"
                  src="/assets/logo-F.png"
                  style={{ width: "160px", cursor: "pointer" }}
                  onClick={handlenavHome}
                />
              </Typography>
              <div className="lg-Search d-none d-sm-block">
                <Search>
                  <SearchIconWrapper>
                    <IoIosSearch style={{ color: "#D357A7" }} />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search What u like...."
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </Search>
              </div>

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <HiMiniInboxArrowDown
                    className="head-Icon"
                    onClick={handlenavOrder}
                  />
                </IconButton>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  className="position-relative"
                  color="inherit"
                >
                  <CgProfile className="head-Icon" onClick={handleSignupOpen} />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge
                    badgeContent={
                      userData.wishlist.length > 0
                        ? userData.wishlist.length
                        : "0"
                    }
                    color="error"
                  >
                    <IoMdHeart className="head-Icon" onClick={handlenavFav} />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge
                    badgeContent={
                      userData.cart.length > 0 ? userData.cart.length : "0"
                    }
                    color="error"
                  >
                    <IoCartSharp
                      className="head-Icon"
                      onClick={handlenavCart}
                    />
                  </Badge>
                </IconButton>
              </Box>
              {/* Signup */}
              <Modal
                open={openSignup}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div>
                    <div className="signupbox">
                      <div className="d-flex justify-content-between align-items-center pb-3">
                        <div>
                          <h3 className="mb-0 signup-Head">
                            Create New Account
                          </h3>
                        </div>
                        <div>
                          <GiCancel
                            style={{ color: "#D327A1", cursor: "pointer" }}
                            onClick={handleSignupClose}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="px-4">
                          <label className="py-2">
                            Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          <input
                            className="p-1"
                            onChange={(e) =>
                              setsignupData({
                                ...signupData,
                                name: e.target.value,
                              })
                            }
                          />
                          <br />

                          <label className="py-2">
                            Email ID <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          <input
                            className="p-1"
                            onChange={(e) =>
                              setsignupData({
                                ...signupData,
                                email: e.target.value,
                              })
                            }
                          />
                          <br />
                          <label className="py-2">Phone Number</label>
                          <br />
                          <input
                            className="p-1"
                            onChange={(e) =>
                              setsignupData({
                                ...signupData,
                                phone: Number(e.target.value),
                              })
                            }
                          />
                          <br />
                          <label className="py-2">
                            Password <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          <input
                            className="p-1"
                            onChange={(e) =>
                              setsignupData({
                                ...signupData,
                                password: e.target.value,
                              })
                            }
                          />
                          <br />
                          <div className="text-center py-3">
                            <Button variant="contained" onClick={handleSignup}>
                              Create
                            </Button>
                          </div>
                          <span>
                            Already Have an Account?
                            <a onClick={handleAlreadyuser}>Login here</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>

              {/* Login */}
              <Modal
                open={openlogin}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div>
                    <div className="signupbox">
                      <div className="d-flex justify-content-between align-items-center pb-3">
                        <div>
                          <h3 className="mb-0 login-Head">Log In</h3>
                        </div>
                        <div>
                          <GiCancel
                            style={{ color: "#D327A1", cursor: "pointer" }}
                            onClick={() => setOpenlogin(false)}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="px-4">
                          <label className="py-2">Email ID</label>
                          <br />
                          <input
                            className="p-1"
                            onChange={(e) =>
                              setLoginData({
                                ...loginData,
                                email: e.target.value,
                              })
                            }
                          />
                          <br />
                          <label className="py-2">Password</label>
                          <br />
                          <input
                            className="p-1"
                            onChange={(e) =>
                              setLoginData({
                                ...loginData,
                                password: e.target.value,
                              })
                            }
                          />
                          <br />
                          <div className="text-center py-3">
                            <Button variant="contained" onClick={handleSigniN}>
                              Sign In
                            </Button>
                          </div>
                          <span>
                            New Customer?
                            <a onClick={handlenavcreateAccount}>
                              Create Your account
                            </a>
                          </span>
                          <br />
                          <span>
                            Forget password?
                            <a onClick={handlenavForgetpass}>
                              Recovery password
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>

              {/* ForgetPassword */}
              <Modal
                open={openforgetpass}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div>
                    <div className="signupbox">
                      <div className="d-flex justify-content-between align-items-center pb-3">
                        <div>
                          <h3 className="mb-0 signup-Head">Forgot Password</h3>
                        </div>
                        <div>
                          <GiCancel
                            style={{ color: "#D327A1", cursor: "pointer" }}
                            onClick={() => setOpenforgetpass(false)}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="px-4">
                          <label className="py-2">Email Address</label>
                          <br />
                          <input
                            className="p-1"
                            onChange={(e) => setForgetEmail(e.target.value)}
                          />
                          <br />
                          {passMessage!==""&& <p className="text-center pt-2" style={{color:"green"}}>{passMessage}</p>}
                          <div className="text-center py-3">
                            {isloading? <Button variant="contained" className="mx-2">
                              Sending...
                            </Button>:
                            <Button variant="contained" className="mx-2" onClick={handleforgetPassword}>
                              Send
                            </Button>}
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>

              {/* Reset Password */}
              <Modal
                open={openresetpass}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div>
                    <div className="signupbox">
                      <div className="d-flex justify-content-between align-items-center pb-3">
                        <div>
                          <h3 className="mb-0 signup-Head">Reset Password</h3>
                        </div>
                        <div>
                          <GiCancel
                            style={{ color: "#D327A1", cursor: "pointer" }}
                            onClick={() => setOpenforgetpass(false)}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="px-4">
                          <label className="py-2">New Password</label>
                          <br />
                          <Input.Password placeholder="New password" onChange={(e) => setResetPass({...resetPass,password:e.target.value})}
                          />
                          <br />
                          <label className="py-2">Conform Password</label>
                          <br />
                          <Input.Password placeholder="Conform password"onChange={(e) => setResetPass({...resetPass,conformPass:e.target.value})}
                          />

                          <br/>
                          <div className="text-center py-3">
                            {/* <Button variant="contained" className="mx-2" onClick={handleupdatePassword}>
                              Update
                            </Button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>

              {/* Profile */}

              <Modal
                open={openProfile}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div>
                    <div className="signupbox">
                      <div className="d-flex justify-content-between align-items-center pb-3">
                        <div>
                          <h3 className="mb-0 profile-Head">Profile</h3>
                        </div>
                        <div>
                          <GiCancel
                            style={{ color: "#D327A1", cursor: "pointer" }}
                            onClick={() => setOpenProfile(false)}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="px-4">
                          <table className="profile-table">
                            <tr>
                              <td>Name</td>
                              <td>{userData.name}</td>
                            </tr>
                            <tr>
                              <td>Mail Id</td>
                              <td>{userData.email}</td>
                            </tr>
                            <tr>
                              <td>Phone Number</td>
                              <td>{userData.phone}</td>
                            </tr>
                          </table>
                          <div className="text-center py-3">
                            <Button
                              variant="contained"
                              className="mx-2"
                              onClick={handleLogout}
                            >
                              Log Out
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>

              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge     badgeContent={
                      userData.cart.length > 0 ? userData.cart.length : "0"
                    } color="error">
                    <IoCartSharp
                      className="head-Icon"
                      onClick={handlenavCart}
                    />
                  </Badge>
                </IconButton>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
      <div>
        <Offcanvas show={show}>
          <Offcanvas.Header
            closeButton
            onClick={handleClose}
            style={{ marginTop: "90px" }}
          >
            <Offcanvas.Title style={{ fontWeight: 700 }}>
              {" "}
              <div className="lg-Search md-search">
                <Search>
                  <SearchIconWrapper>
                    <IoIosSearch style={{ color: "#D357A7" }} />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search What u like...."
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </Search>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div
              className="px-3 pb-3 sm-sidebar"
              style={{ backgroundColor: "#fff", borderRadius: "20px" }}
            >
              <ul className="px-0 mobile-ul">
                <li>
                  {" "}
                  <Link to="/" className="mobile-menu">
                    Home
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to=""
                    onClick={handleSignupOpen}
                    className="mobile-menu"
                  >
                    Profile
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/my-orders" className="mobile-menu">
                    My Orders
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/my-wishlist" className="mobile-menu">
                    Favourites
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/product-category/embroidery-designs" className="mobile-menu">
                    Embroidery Design
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/product-category/print-designs" className="mobile-menu">
                    Print Design
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/custom-digitizing" className="mobile-menu">
                    Custom Digitizing
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/freebies" className="mobile-menu">
                    Freebies
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/coupon" className="mobile-menu">
                    Coupons
                  </Link>
                </li>
              </ul>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
}
